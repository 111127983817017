<template>
    <button
        v-bind="$attrs"
        :class="{'base-button__active': isButtonActive}"
        class="base-button"
        :disabled="disabled"
    >
        <span v-if="isLoading" class="loading-circle"></span>

        <slot v-else></slot>
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        disabled: {type: Boolean, default: false},
        isLoading: {type: Boolean, default: false},
        isButtonActive: {type: Boolean, default: false},
    },
}
</script>

<style scoped>
.base-button {
    @apply flex items-center justify-center shrink-0 w-full h-[48px] rounded-[100px] bg-[--primary-typography-color] font-[700] text-[14px] text-[--typography-color-white];

    &:disabled {
        @apply opacity-30;
    }
}

.base-button__active {
    @apply bg-[--background-color-active-2];
}
</style>
